import type { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import type { ShouldRevalidateFunction } from "@remix-run/react";
import type { ClientError } from "graphql-request";
import { redirect } from "@remix-run/node";
import {
  useNavigation,
  useSearchParams,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import NProgress from "nprogress";
import React, { useEffect, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SiteNav from "~/components/site-nav";
import { CurrentUser } from "~/contexts";
import { getCurrentUser } from "~/models/user.server";
import {
  destroySession,
  getSession,
  stopShadowingUser
} from "~/sessions.server";
import { DefaultErrorBoundary } from "~/utils/remix";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "~/styles/bootstrap.css";
import "~/styles/app.css";
import "~/styles/src/components/Badge/Style.css";
import "~/styles/src/components/NumberedTable/styles.css";
import "~/styles/src/components/RichEditor/Slate.css";
import "~/styles/src/components/Table/styles.css";
import "~/styles/src/components/Tabs/Style.css";
import "~/styles/src/routes/contacts/Imports/styles.css";
import "~/styles/nProgress.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-image-crop/dist/ReactCrop.css";

export const meta = () => [{ title: "PFCS Intranet" }];

const OPEN_ROUTES = [
  "/login",
  "/privacy",
  "/reset-password",
  "/update-password"
];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(request.headers.get("Cookie"));
  const url = new URL(request.url);

  const openPage = OPEN_ROUTES.includes(url.pathname);
  // Redirect to the login page if they are not logged in
  if (!openPage && !session.has("token")) {
    throw redirect("/login");
  }

  // Otherwise, set the current user. If we have a cookie/token but the user isn't found,
  // that means the user was deactivated and we should log them out.
  let cu;
  try {
    cu = openPage ? null : (await getCurrentUser(request)).currentUser;
  } catch (error) {
    if ((error as ClientError).message.startsWith("unauthenticated")) {
      throw redirect("/login", {
        headers: {
          "Set-Cookie": await destroySession(session)
        }
      });
    }
    throw error;
  }

  const ip = request.headers.get("x-real-ip") || "N/A";

  return {
    cu,
    ip,
    isShadowing: !!session.get("suToken"),
    token: session.get("token"),
    version: "xx"
  };
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const fd = await request.formData();
  if (fd.get("intent") === "stop-shadowing") {
    const href = fd.get("href") as string;
    return await stopShadowingUser(request, href);
  } else {
    // Trying to debug why this happens
    // Convert formdata to object
    const obj: { [key: string]: string } = {};
    fd.forEach((value, key) => {
      obj[key] = value as string;
    });
    Sentry.addBreadcrumb({
      category: "action",
      message: "form data",
      data: obj,
      level: "info"
    });
  }
};

// Only revalidate if we're changing pages or have successfully submitted a form
export const shouldRevalidate: ShouldRevalidateFunction = (args) => {
  return (
    args.currentUrl.pathname !== args.nextUrl.pathname ||
    (!!args.formMethod &&
      args.formMethod !== "get" &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      !(args.actionResult as any)?.errors)
  );
};

export const ErrorBoundary = () => (
  <html lang="en">
    <head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      <Meta />
      <Links />
    </head>
    <body className="p-20">
      <DefaultErrorBoundary />
    </body>
  </html>
);

NProgress.configure({ speed: 500, trickleSpeed: 500, showSpinner: false });

export default function App() {
  const data = useLoaderData<typeof loader>();
  const location = useLocation();
  const [showWarning, setShowWarning] = React.useState(false);
  const nav = useNavigation();

  Sentry.addBreadcrumb({
    category: "root",
    message: "current user",
    data: { user: data.cu?.fullname || "N/A" },
    level: "info"
  });

  Sentry.getCurrentScope().setUser({
    id: data.cu?.fullname || "Logged Out"
  });

  // Only show a progress bar if the current page is taking more than half a
  // second to load
  useEffect(() => {
    if (nav.state === "idle") {
      NProgress.done();
      return;
    }
    const t = setTimeout(() => NProgress.start(), 500);
    return () => clearTimeout(t);
  }, [nav.state]);

  useEffect(() => {
    if (typeof String.prototype.matchAll === "undefined") {
      setShowWarning(true);
    }
  }, []);

  useEffect(() => {
    if (import.meta.env.PROD && data.cu && !data.isShadowing) {
      fetch(
        `/resources/access-logs/track?url=${encodeURIComponent(
          `${location.pathname}${location.search}`
        )}`,
        { method: "get" }
      ).catch(() => {});
    }
  }, [location, data.cu, data.isShadowing]);

  useEffect(() => {
    if (typeof document === "undefined" || import.meta.env.PROD) return;

    import("react-scan").then(({ scan }) => {
      scan({
        includeChildren: true,
        log: true,
        report: true
      });
    });
  }, []);

  // Ugh, hacky solution to get HMR to work correctly with react-dnd
  // Inspired by https://github.com/vitejs/vite/issues/3301#issuecomment-1223570831
  const dndProviderRef = useRef<typeof DndProvider | null>(null);
  const MyDndProvider = (dndProviderRef.current ??= DndProvider);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className={import.meta.env.DEV ? "body-dev" : ""}>
        <div
          style={showWarning ? undefined : { display: "none" }}
          className="bg-yellow-600 px-16 py-16 text-3xl font-bold text-white"
        >
          You are using an unsupported browser and may encounter broken pages.
          Please make sure you are using a recent version of Google Chrome or
          Microsoft Edge.
        </div>

        <CurrentUser.Provider value={data.cu || null}>
          <MyDndProvider backend={HTML5Backend}>
            {data.cu ? (
              <SiteNav isShadowing={data.isShadowing}>
                <Outlet />
              </SiteNav>
            ) : (
              <Outlet />
            )}
          </MyDndProvider>
        </CurrentUser.Provider>
        <ConditionalScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function ConditionalScrollRestoration() {
  const [params] = useSearchParams();
  if (params.get("scroll") === "false") {
    return null;
  }
  return <ScrollRestoration />;
}
